import React from 'react'
import { t } from '../../../common/i18n'
import CheckIcon from './assets/check_icon.svg'
import Badge from '../../../shared/components/Badge/Badge'

const CompatibleBadge: React.FC = () => {
  return (
    <Badge
      icon={<CheckIcon />}
      text={t('baterias.compatible')}
      alt="Compatibility Icon"
    />
  )
}

export default CompatibleBadge
