import React from 'react'
import styles from './BateriaListCard.module.scss'
import { intl, t } from '../../../common/i18n'
import { Bateria } from '../type/bateria'
import CompatibleBadge from './CompatibleBadge'
import GarantiaBadge from './GarantiaBadge'
import Button from '../../../shared/components/Button/Button'

interface RowProps {
  bateria: Bateria
  onSelectBateria: (bateria: Bateria) => void
}

const defaultBateriaImage =
  'https://media.rodi.es/marca/default/neumaticos/recortado/imagen-neumatico.png' // Use your actual path

interface LogoProps {
  slug: string | null
}

const Logo = ({ slug }: LogoProps) => {
  let Image
  try {
    Image = require(`../../../common/assets/images/logo_marcas/${slug}.svg`)
  } catch (e) {
    Image = require(`../../../common/assets/images/logo_baterias/no-marca.svg`)
  }
  return <Image className={styles.bateriasListCard__logoMarca} />
}

const BateriaListCard: React.FC<RowProps> = ({ bateria, onSelectBateria }) => {
  return (
    <article className={styles.bateriasListCard}>
      <div className={styles.bateriasListCard__cardContent}>
        <div className={styles.bateriasListCard__images}>
          <div className={styles.bateriasListCard__logoMarca}>
            <Logo slug={bateria.logo} />
          </div>
          <div
            className={styles.bateriasListCard__image}
            style={{
              backgroundImage: `url(${
                bateria.urlImagenRecorte || defaultBateriaImage
              })`,
            }}
          />
        </div>
        <div className={styles.bateriasListCard__content}>
          <div className={styles.bateriasListCard__content__name}>
            {bateria.nombre_producto_es}
          </div>
          <div className={styles.bateriasListCard__content__tension}>
            <label>{t('baterias.list.elemento.tension')}:</label>
            {bateria.voltaje} V
          </div>
          <div className={styles.bateriasListCard__content__bornes}>
            <label>{t('baterias.list.elemento.bornes')}:</label>
            {bateria.posicion_positivo}
          </div>
          <div className={styles.bateriasListCard__content__amperaje}>
            <label>{t('baterias.list.elemento.amperaje')}:</label>
            {bateria.amperaje} Ah
          </div>
          <div className={styles.bateriasListCard__content__tipo}>
            <label>{t('baterias.list.elemento.potencia_arranque')}:</label>
            {bateria.potencia_arranque} A
          </div>
        </div>
        <div className={styles.bateriasListCard__actions}>
          <div className={styles.bateriasListCard__actions__badges}>
            <CompatibleBadge />
            <GarantiaBadge />
          </div>
          {bateria.descuento > 0 && (
            <>
              <div
                className={styles.bateriasListCard__actions__discountWrapper}>
                <div
                  className={
                    styles.bateriasListCard__actions__discountWrapper__discountTag
                  }>
                  {t('baterias.descuento_placeholder')}
                </div>
              </div>
              <div className={styles.bateriasListCard__actions__oldPrice}>
                {intl.formatNumber(bateria.precio, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
            </>
          )}
          <div className={styles.bateriasListCard__actions__ctaWrapper}>
            <div
              className={styles.bateriasListCard__actions__ctaWrapper__price}>
              {intl.formatNumber(bateria.precio - bateria.descuento, {
                style: 'currency',
                currency: 'EUR',
              })}
            </div>
            <Button
              buttonClass={'elegir_button'}
              onClick={() => onSelectBateria(bateria)}>
              {t('baterias.list.elegir')}
            </Button>
          </div>
        </div>
      </div>
    </article>
  )
}

export default BateriaListCard
